/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    em: "em",
    strong: "strong",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Una de las funcionalidades de nuestros archivos de Octave que he mantenido en secreto por ahora, en el curso, es que es posible definir nuestros propios sistemas y controlarlos con estos ", React.createElement(_components.em, null, "scripts"), ". Todas las funciones aceptan un argumento en forma de sistema, este puede definirse de muchas formas y me remitiré sin más a la documentación de la Toolbox de Control de Octave, yo en este caso he optado por introducir los sistemas en ", React.createElement(_components.strong, null, "Función de Transferencia"), ", no es el objetivo de este curso introductorio explicar que quiere decir esto, sólo diré por si tenéis curiosidad que utiliza la Transformada de Laplace cuando estamos en tiempo continuo."), "\n", React.createElement(_components.p, null, "Por ejemplo para el control P usaríamos la siguiente sintaxis:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"matlab\"><pre class=\"language-matlab\"><code class=\"language-matlab\"><span class=\"token function\">controlP</span><span class=\"token punctuation\">(</span>kp <span class=\"token punctuation\">,</span> sistema<span class=\"token punctuation\">)</span></code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Ahora vamos a ver distintos sistemas que podemos probar , sólo es necesario sustituir “sistema” en el anterior comando por lo que pongo a continuación:"), "\n", React.createElement(_components.p, null, "Este es un sistema más fácil del que hemos visto, se trata de un sistema de 1º Orden y la teoría nos dice que es posible controlarlo completamente con tan sólo un PI, a ver si lo conseguís:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"matlab\"><pre class=\"language-matlab\"><code class=\"language-matlab\"><span class=\"token function\">tf</span><span class=\"token punctuation\">(</span><span class=\"token punctuation\">[</span><span class=\"token number\">1</span><span class=\"token punctuation\">]</span><span class=\"token punctuation\">,</span><span class=\"token punctuation\">[</span><span class=\"token number\">1</span><span class=\"token punctuation\">,</span> <span class=\"token number\">1</span><span class=\"token punctuation\">]</span><span class=\"token punctuation\">)</span></code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "En siguiente sistema consta de un Integrador, es decir como la acción integral, por lo tanto ", React.createElement(_components.strong, null, "no necesitaremos acción integral"), " para garantizar que llegue al punto deseado ¡Llega solo!"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"matlab\"><pre class=\"language-matlab\"><code class=\"language-matlab\"><span class=\"token function\">tf</span><span class=\"token punctuation\">(</span><span class=\"token punctuation\">[</span><span class=\"token number\">1</span><span class=\"token punctuation\">]</span><span class=\"token punctuation\">,</span><span class=\"token punctuation\">[</span><span class=\"token number\">1</span><span class=\"token punctuation\">,</span> <span class=\"token number\">0.1</span><span class=\"token punctuation\">,</span> <span class=\"token number\">0</span><span class=\"token punctuation\">]</span><span class=\"token punctuation\">)</span></code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "El último sistema que vamos a ver es un sistema de 2º Orden como el primero que vimos pero con una particularidad, tiene lo que en control llamamos un ", React.createElement(_components.strong, null, "cero."), " ¿Me podéis decir que efectos provoca este ", React.createElement(_components.em, null, "cero"), "?"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"matlab\"><pre class=\"language-matlab\"><code class=\"language-matlab\"><span class=\"token function\">tf</span><span class=\"token punctuation\">(</span><span class=\"token punctuation\">[</span><span class=\"token number\">1</span> <span class=\"token number\">0.1</span><span class=\"token punctuation\">]</span><span class=\"token punctuation\">,</span><span class=\"token punctuation\">[</span><span class=\"token number\">1</span><span class=\"token punctuation\">,</span> <span class=\"token operator\">-</span><span class=\"token number\">0.1</span><span class=\"token punctuation\">,</span> <span class=\"token number\">0.1</span><span class=\"token punctuation\">]</span><span class=\"token punctuation\">)</span></code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Con esto y algún apunte más terminamos la parte básica del curso, me surgen diferentes caminos que podría tomar a partir de ahora, así que lo meditaré y posiblemente el curso y las novedades que le rodean tengan un parón en la frecuencia de publicación. Eso espero que me permita retomar otros temas del blog que he dejado más apartados."), "\n", React.createElement(_components.p, null, "Los que habéis llegado hasta aquí: ¡Enhorabuena! Ya tenéis una idea de qué es el control y de como controlar alguna cosilla fácil, si completáis todas las actividades tendréis un premio."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
